<template>
  <div>
    <section class="banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>DECENTRALIZE THE WEB</h1>
          </div>
        </div>
        <div class="row mt-5 pt-lg-5">
          <div class="col-lg-12">
            <div class="layer_a">
              <div class="inner_text">
                <p>
                  Boosting over 42000 community members until 2023, POX is
                  outrageous growing DPoS protocol
                </p>
                <div class="row mt-5 placeholder-glow">
                  <div
                    class="col-6 col-sm-6 col-md-3"
                    v-for="(data_a, index) in data_a"
                    :key="index"
                  >
                    <div class="inner_content mb-4 mb-md-0">
                      <h3 :class="loader ? 'placeholder w-100 d-block' : ''">
                        {{ data_a.number }}
                      </h3>
                      <p class="txt m-0">{{ data_a.text }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="insight">
      <div class="container">
        <div class="row mb-lg-5 mb-4">
          <div class="col-lg-12 text-center">
            <SubHeading subheading="POX Market Performance" />
            <p class="sub_line lg_gray">
              Listed on over 130 exchanges, POX, on of the most promising
              cryptos, connects millions of value investors across the globe.
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-9">
            <canvas id="myChart" class="d-none"></canvas>
            <div class="graph mb-0">
              <ChartComponent />
            </div>
          </div>
          <div class="col-md-3">
            <div
              class="row align-items-center justify-content-center placeholder-glow"
            >
              <div
                class="col-6 col-sm-4 col-md-12"
                v-for="(data_e, index) in data_e"
                :key="index"
              >
                <div class="inner_text mb-xl-4">
                  <span class="ranking lg_gray">{{ data_e.ranking }}</span>
                  <h1
                    class="values text-black"
                    :class="loader ? 'placeholder w-100 d-block' : ''"
                  >
                    {{ data_e.values }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center placeholder-glow mt-3">
          <div
            class="col-6 col-sm-4 border"
            v-for="(data_f, index) in data_f"
            :key="index"
          >
            <div class="inner_text">
              <span class="ranking lg_gray">{{ data_f.ranking }}</span>
              <h1
                class="values text-black"
                :class="loader ? 'placeholder w-100 d-block' : ''"
              >
                {{ data_f.values }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="slider">
      <div class="container">
        <div class="inner_text text-center">
          <div class="row mb-lg-5 mb-4">
            <div class="col-lg-12">
              <SubHeading subheading="POX Ecosystem" />
            </div>
          </div>
          <SwiperSlider />
        </div>
      </div>
    </section>

    <section class="features">
      <div class="container">
        <div class="row mb-lg-5 mb-4">
          <div class="col-lg-12 text-center">
            <SubHeading subheading="POXPROTOCOL Features" />
            <p class="sub_line">
              POXPROTOCOL is the world's leading decentralized blockchain
              protocol
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-sm-6 col-md-4 mb-5 mb-lg-0"
            v-for="(data_b, index) in data_b"
            :key="index"
          >
            <div
              class="inner_text text-center p-2 p-md-2 p-lg-4"
              :id="'box_clr' + index"
            >
              <h4 class="mb-4">{{ data_b.head }}</h4>
              <p class="para">{{ data_b.para }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="network">
      <div class="container">
        <div class="row text-center text-lg-start">
          <div class="col-lg-6">
            <div class="mb-4">
              <SubHeading subheading="POX Network" />
            </div>
            <p class="para_ln">
              DAppChain is POX's sidechain project. The goal is to help DApps to
              operate on POX with lower energy consumption, faster speed and
              enhanced safety, providing unlimited capacity for POX's main
              network.
            </p>
            <a href="#" class="ach_btn mt-4 d-inline-block">Learn More</a>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="core">
      <div class="container">
        <div class="row mb-lg-5 mb-4">
          <div class="col-lg-12 text-center">
            <SubHeading subheading="Core Partners" />
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-4  text-center" v-for="(data_c, index) in data_c" :key="index">
            <figure>
              <img :src="require(`@/assets/img/${data_c.img}.svg`)" alt="" class="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
    </section> -->

    <section class="insight">
      <div class="container">
        <div class="row mb-lg-5 mb-4">
          <div class="col-lg-12 text-center">
            <SubHeading subheading="Insights" />
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-sm-6 col-md-4"
            v-for="(data_d, index) in data_d"
            :key="index"
          >
            <div class="inner_text mb-5 mb-md-0">
              <figure>
                <img
                  :src="require(`@/assets/img/${data_d.img}.png`)"
                  alt=""
                  class="img-fluid"
                />
              </figure>
              <!-- <p class="blog_title text-black m-0">{{ data_d.blogs_txt }}</p>
              <span class="dated">{{ data_d.dated }}</span> -->
            </div>
          </div>
          <div class="text-center mt-md-5">
            <a href="#" class="ach_btn mt-4 d-inline">Learn More</a>
          </div>
        </div>
      </div>
    </section>

    <footer class="pb-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-xl-3">
            <div
              class="inner_text text-sm-center text-xl-start mb-sm-5 mb-xl-0"
            >
              <div class="logo">
                <img src="../assets/img/logo.png" alt="" class="img-fluid" />
              </div>
              <p class="mt-4">
                POX is the primary currency used in the Pollux network. POX can
                be used to vote for super representatives and obtain bandwidth.
                Staking the POX balance in a wallet gives the user Pollux Power
                (PP), which is used to vote for Super Representatives (SRs).
              </p>
            </div>
          </div>
          <div class="col-sm-4 col-xl-3">
            <h5>Social Media</h5>
            <ul class="m-0 p-0">
              <li>
                <a target="_blank" href="https://t.me/polluxchainDAO"
                  >Telegram</a
                >
              </li>
              <li>
                <a href="javascript:void(0)">Twitter</a>
              </li>
              <li>
                <a target="_blank" href="https://www.facebook.com/PolluxCoin"
                  >Facebook</a
                >
              </li>
              <li>
                <a href="javascript:void(0)">YouTube</a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 col-xl-3">
            <h5>Social Media</h5>
            <ul class="m-0 p-0">
              <li><a href="javascript:void(0)">LinkedIn</a></li>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/polluxchainofficial/"
                  >Instagram</a
                >
              </li>
              <!-- <li><a href="javascript:void(0)">Reddit</a></li>
              <li><a href="javascript:void(0)">VK</a></li>
              <li><a href="javascript:void(0)">Kakao</a></li> -->
                            <li>
                <a target="_blank" href="https://discord.com/invite/JXHGsfPj"
                  >Discord</a
                >
              </li>
            </ul>
          </div>
          <div class="col-sm-4 col-xl-3">
            <h5>Support</h5>
            <ul class="m-0 p-0">
              <li><a href="javascript:void(0)">Privacy Policy</a></li>
              <li><a href="javascript:void(0)">FAQs</a></li>
              <li><a  target="_blank"
                                            href="./whitepaper.pdf">Whitepaper</a></li>
              <!-- <li><a href="javascript:void(0)">Whistlelower Notice</a></li> -->
              <li><a href="javascript:void(0)">Contact Us</a></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner_text text-center">
              <p class="copyright">© 2023 POX All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import SwiperSlider from "@/components/SwiperSlider.vue";
import SubHeading from "@/components/SubHeading.vue";
import { Line } from "vue-chartjs";
import ChartComponent from "@/components/ChartComponent.vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    SwiperSlider,
    SubHeading,
    ChartComponent,
  },

  data() {
    return {
      data_a: [
        {
          number: "216,417,516",
          text: "Total Number of Accounts",
        },
        {
          number: "7,250,296,832",
          text: "Transaction Count",
        },
        {
          number: "59,751,581",
          text: "Block Height",
        },
        {
          number: "$11,070.13 B",
          text: "Transfer Amount of Core Tokens",
        },
      ],
      data_b: [
        {
          head: "High-throughput",
          para: "The high throughput of POXPROTOCOL, underpinned by improved network  TPS, makes POXa better option for day-to-day use than Bitcoin and  Ethereum.",
        },
        {
          head: "High-scalability",
          para: "Strong scalability and smart contracts allow applications to be deployed  on POX in diverse ways, enabling POXPROTOCOL to accommodate a sizable  user base.",
        },
        {
          head: "High-availability",
          para: "POXPROTOCOL has a more reliable network structure, user assets, and  intrinsic value. Its further decentralized consensus brings a more  mature rewards distribution mechanism.",
        },
      ],
      // data_c: [
      //   {
      //     img: "logo_a",
      //   },
      //   {
      //     img: "logo_b",
      //   },
      //   {
      //     img: "logo_c",
      //   },
      //   {
      //     img: "logo_d",
      //   },
      //   {
      //     img: "logo_e",
      //   },
      //   {
      //     img: "logo_f",
      //   },
      // ],
      data_d: [
        {
          img: "img_a",
          // blogs_txt: "Lorem ipsum dolor sit amet, consect adipiscing elit.",
          // dated: "12-Feb-2021"
        },
        {
          img: "img_b",
          // blogs_txt: "Lorem ipsum dolor sit amet, consect adipiscing elit.",
          // dated: "12-Feb-2021"
        },
        {
          img: "img_c",
          // blogs_txt: "Lorem ipsum dolor sit amet, consect adipiscing elit.",
          // dated: "12-Feb-2021"
        },
      ],
      data_e: [
        {
          ranking: "Total Market Cap",
          values: "$12,079.72M",
        },
        {
          ranking: "Global Rank",
          values: "14",
        },
        {
          ranking: "Accounts holding POX",
          values: "119.56M",
        },
      ],
      data_f: [
        {
          ranking: "Current Price",
          values: "$0.137404",
        },
        {
          ranking: "Transaction Volume (24h)",
          values: "$354.02M",
        },
        {
          ranking: "Transaction Count (24h)",
          values: "4.62M",
        },
      ],
      polluxcoinData: "",
      loader: false,
    };
  },
  extends: Line,
  async mounted() {
    // otherDetails
    this.loader = true;
    let otherDetails = await fetch(
      "https://node.poxscan.io/api/polluxcoinData"
    );
    this.loader = false;
    let data = await otherDetails?.json();
    data = data?.message || [];
    console.log({ data });
    this.data_e[0].values = `$${data?.poxMarketCap?.toFixed(2)}`;
    this.data_e[1].values = data?.poxRank;
    this.data_e[2].values = data?.AccountHolder;

    this.data_f[0].values = data?.poxPrice?.toFixed(4);
    this.data_f[1].values = data?.txnVol24?.toFixed(2);
    this.data_f[2].values = data?.txCount24;

    this.data_a[0].number = data?.totalAccount;
    this.data_a[1].number = data?.totalTxn;
    this.data_a[2].number = data?.blockHeight;
    this.data_a[3].number = `$${data?.poxVol?.toFixed(2)}`;

    this.polluxcoinData = data;
    // Render the chart when the component is mounted
    // this.renderChart(
    //   {
    //     labels: ["January", "February", "March", "April", "May", "June"],
    //     datasets: [
    //       {
    //         label: "Sample Data",
    //         backgroundColor: "rgba(75, 192, 192, 0.2)",
    //         borderColor: "rgba(75, 192, 192, 1)",
    //         borderWidth: 1,
    //         data: [65, 59, 80, 81, 56, 55],
    //       },
    //     ],
    //   },
    //   {
    //     scales: {
    //       yAxes: [
    //         {
    //           ticks: {
    //             beginAtZero: true,
    //           },
    //         },
    //       ],
    //     },
    //   }
    // );
  },
};
</script>

<style lang="scss">
section.banner {
  background-image: url("../assets/img/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  padding: 209px 0 133px;

  h1 {
    font-family: OriginTech;
    font-size: 120px;
  }

  .layer_a {
    border-radius: 30px;
    overflow: hidden;
    padding: 2px;

    background: linear-gradient(
      180deg,
      rgb(239, 170, 45) 6%,
      rgb(0, 0, 0) 46%,
      rgb(0, 0, 0) 65%,
      rgb(239, 170, 45) 100%
    );

    .inner_text {
      border-radius: 30px;
      padding: 38px 20px;
      background-color: #2f2f2f;

      p {
        font-size: 20px;
      }

      h3 {
        font-family: OriginTech;
        font-size: 40px;
      }

      .inner_content {
        .txt {
          color: #aeaeae;
        }
      }
    }
  }
}

section.features {
  h4 {
    font-family: OriginTech;
    font-size: 30px;
  }

  .inner_text {
    height: 300px;
  }

  #box_clr0,
  #box_clr1,
  #box_clr2,
  #box_clr3 {
    position: relative;
  }

  #box_clr0::before,
  #box_clr1::before,
  #box_clr2::before,
  #box_clr3::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #571d61;
    z-index: -1;
  }

  #box_clr0::after,
  #box_clr1::after,
  #box_clr2::after,
  #box_clr3::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: #000;
    z-index: -1;
    border-radius: 100%;
    box-shadow: 10px;
    filter: blur(10px);
  }

  #box_clr1::before {
    background-color: #164979 !important;
  }

  #box_clr2::before {
    background-color: #2c550b !important;
  }
}

section.network {
  position: relative;
}

section.network:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("../assets/img/network_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ach_btn {
  background-color: #efaa2d;
  padding: 14px 20px;
  color: #000;
  font-weight: bold;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;
}

.para_ln {
  color: #aeaeae;
  font-weight: 300;
  font-size: 22px;
}

section.core {
  figure {
    img {
      margin: 30px 0;
    }
  }
}

section.insight {
  background-color: #fff;

  .blog_title {
    font-size: 22px;
  }

  .dated {
    color: #aeaeae;
    text-align: end;
    display: block;
    font-size: 20px;
  }

  .inner_text {
    h1 {
      font-family: OriginTech;
      font-size: 38px;
    }

    .ranking {
      font-size: 18px;
    }
  }
}

.sub_line {
  font-size: 18px;
}

footer {
  h5 {
    font-size: 26px;
    font-weight: bold;
  }

  ul {
    li {
      list-style: none;

      a {
        padding: 8px 0;
        color: #9e9e9e;
        text-decoration: none;
        font-size: 18px;
        display: inline-block;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .copyright {
    font-size: 18px;
    color: #9e9e9e;
  }
}

.lg_gray {
  color: #aeaeae;
}

.border {
  border: 1px solid #aeaeae;
  padding: 10px;
}

@media (min-width: 320px) and (max-width: 575px) {
  section.banner {
    padding: 139px 0 43px;

    h1 {
      font-size: 38px;
    }

    .layer_a {
      .inner_text {
        padding: 38px 14px;

        p {
          font-size: 14px;
        }

        h3 {
          font-size: 16px;
        }
      }
    }
  }

  .para_ln {
    font-size: 16px;
  }

  section.insight {
    .inner_text {
      h1 {
        font-size: 26px;
      }

      .ranking {
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  section.banner {
    padding: 139px 0 43px;

    h1 {
      font-size: 66px;
    }

    .layer_a {
      .inner_text {
        padding: 38px 14px;

        p {
          font-size: 18px;
        }

        h3 {
          font-size: 30px;
        }
      }
    }
  }

  .para_ln {
    font-size: 16px;
  }

  section.insight {
    .blog_title {
      font-size: 16px;
    }

    .dated {
      font-size: 14px;
    }

    .inner_text {
      h1 {
        font-size: 26px;
      }

      .ranking {
        font-size: 13px;
      }
    }
  }

  footer h5 {
    font-size: 20px;
  }

  footer {
    ul {
      li {
        a {
          padding: 6px 0;
          font-size: 16px;
        }
      }
    }

    .copyright {
      font-size: 16px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  section.banner {
    padding: 139px 0 43px;

    h1 {
      font-size: 66px;
    }

    .layer_a {
      .inner_text {
        padding: 38px 14px;

        p {
          font-size: 16px;
        }

        h3 {
          font-size: 24px;
        }
      }
    }
  }

  .para_ln {
    font-size: 16px;
  }

  section.insight {
    .blog_title {
      font-size: 16px;
    }

    .dated {
      font-size: 14px;
    }

    .inner_text {
      h1 {
        font-size: 26px;
      }

      .ranking {
        font-size: 13px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  section.banner {
    padding: 149px 0 43px;

    h1 {
      font-size: 90px;
    }

    .layer_a {
      .inner_text {
        h3 {
          font-size: 24px;
        }
      }
    }
  }

  .para_ln {
    font-size: 16px;
  }

  section.insight {
    .blog_title {
      font-size: 16px;
    }

    .dated {
      font-size: 14px;
    }

    .inner_text {
      h1 {
        font-size: 26px;
      }

      .ranking {
        font-size: 16px;
      }
    }
  }
}
</style>
