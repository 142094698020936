<template>
  <div>
    <TopHeader />
  </div>
  
  <router-view />
</template>



<script>
import TopHeader from "@/components/TopHeader.vue"

export default {
  name: "App",
  components: {
    TopHeader
  }
}
</script>

<style lang="scss">

@font-face {
  font-family:OriginTech ;
  src: url('../src/assets/fonts/OriginTech\ personal\ use.ttf');
}
@font-face {
  font-family:Archivo ;
  src: url('../src/assets/fonts/OutfitLight1.ttf');
}


section,footer{
  padding: 80px 0;
}

body{
  background-color: #000 !important;
  color:#fff !important;
  font-family: Archivo;
}


p{
  font-family: Archivo;
}
li{
  list-style: none;
}


.navbar-expand-lg .navbar-nav .nav-link {
	padding-right:unset !important;
	padding-left: unset !important;
}


@media(min-width:320px) and (max-width:767px){
  section,footer{
    padding: 50px 0;
  }
}
</style>
