<template>
    <header>
        <nav class="navbar navbar-expand-lg bg-transparent">
            <div class="container">
                <a class="navbar-brand logo" href="#">
                    <img src="../assets/img/logo.png" alt="" class="img-fluid">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="toggler-icon top-bar"></span>
                    <span class="toggler-icon middle-bar"></span>
                    <span class="toggler-icon bottom-bar"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <!-- <li class="nav-item">
                            <a class="nav-link nav_link_a" aria-current="page" href="#">Home</a>
                        </li> -->

                        <li class="nav-item">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdow"
                                aria-expanded="false">
                                Ecosystem
                                <ul class="dropdown-men  ">
                                    <span class="line"></span>
                                    <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="http://poxscan.io/governance/super-representatives">POX SR</a></li>
                                    <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="http://poxscan.io/wallet">Wallet</a>
                                    </li>
                                    <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="http://poxscan.io/">Explorer</a></li>
                                            <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="https://chromewebstore.google.com/detail/polink/afeibjjgfjfphjedhdjgbgbhpomolbjm?hl=en-US&utm_source=ext_sidebar">Extension</a></li>

                                </ul>
                            </a>
                        </li>

                        <li class="nav-item ">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdow"
                                aria-expanded="false">
                                Coin
                                <ul class="dropdown-men  ">
                                    <span class="line"></span>
                                    <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="http://poxscan.io/token/pox">POX</a>
                                    </li>

                                </ul>
                            </a>
                        </li>
                        <li class="nav-item dropdow">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdow"
                                aria-expanded="false">
                                Developers
                                <ul class="dropdown-men">
                                    <span class="line"></span>
                                    <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="https://pox-chain.gitbook.io/doc">Developer Doc</a></li>
                                    <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="https://github.com/polluxchain">GitHub</a>
                                    </li>
                                    <li><a class="dropdown-item nav_link_a" target="_blank"
                                            href="./whitepaper.pdf">Whitepaper</a>
                                    </li>

                                </ul>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link nav_link_a" aria-current="page" href="#">About</a>
                        </li> -->
                    </ul>

                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    name: "TopHeader",
    mounted: function () {
        window.addEventListener('scroll', function () {
            if (window.pageYOffset > 200) {
                document.querySelector('header').classList.add('sticky', 'fadeInDown', 'animated');
            } else {
                document.querySelector('header').classList.remove('sticky', 'fadeInDown', 'animated');
            }
        });
        // add class on scrool in header menu end


        // on click toggle menu close on anchor
        document.querySelectorAll('.nav_link_a').forEach(function (element) {
            element.addEventListener('click', function () {
                document.querySelector('.navbar-collapse').classList.remove('show');
                document.querySelector('.navbar-toggler').classList.add('collapsed');
            });
        });

        // on click toggle menu close on anchor end 




        // on hover function

        var navLinks = document.querySelectorAll('.nav-link');
        var dropdownMenus = document.querySelectorAll('.dropdown-men');

        navLinks.forEach(function (navLink, index) {
            navLink.addEventListener('mouseenter', function () {
                dropdownMenus[index].style.height = 'auto';
                dropdownMenus[index].style.padding = '8px';
            });
            navLink.addEventListener('mouseleave', function () {
                dropdownMenus[index].style.height = '0';
                dropdownMenus[index].style.padding = '0';
            });
        });

    }
}
</script>


<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* for sticky  css start*/
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000;
    z-index: 9999;
}

/* for sticky  css end*/


/**************************************************** animation header css start **********************************************/

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

/**************************************************** animation header css end **********************************************/



/* **************************toggle cross in menu bar ********************************************/

.navbar-toggler {
    border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon {
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    display: block;
    transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar {
    margin: 5px auto;
}

/* State when navbar is opened (START) */

.navbar-toggler[aria-expanded="true"] .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
}

.navbar-toggler[aria-expanded="true"] .middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler[aria-expanded="true"] .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
}

/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    transform: rotate(0);
}

/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
    background-color: #777777;
}

/* **************************toggle cross in menu bar end********************************************/




/* set loder css start */
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    // padding: 10px 0;
}

#cover {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #141526;
    z-index: 9999;
    font-size: 65px;
    text-align: center;
    color: #fff;
    font-family: tahoma;
}

.set_loder {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.nav-link {
    color: #fff !important;
    font-size: 18px;
    &:hover{
        color: #f29201 !important;
    }
}

.nav-item {
    padding: 0 30px;
}

/* set loder css end */

.nav-link {
    .dropdown-men {
        position: absolute;
        background-color: #fff;
        color: #000;
        min-width: 130px;
        height: 0;
        overflow: hidden;
        transition: 0.2s all ease-in-out;
        margin-top: 12px;
        font-size: 16px;

        .dropdown-item {
            line-height: 30px;
            transition: 0.3s all;
            &:hover {
                color: #f29201;
            }
        }


        .line {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: #f29201;
            width: 0;
            transition: 0.2s all ease-in-out;
        }

        ul {
            li {
                a {
                    color: #f29201;
                }
            }
        }

    }

    &:hover {


        .line {
            width: 100%;
        }
    }
}




@media(min-width:320px) and (max-width:575px) {
    header {
        // padding: 10px;

        nav {
            .logo {
                width: 60%;
            }
        }
    }
}

@media(max-width:991px){
    header{
        background-color: #000;
    }
}
</style>