<template>
    <div>
        <!-- <h2></h2> -->
        <h2>{{ subheading }}</h2>
    </div>
</template>

<script>
export default {
    name: "SubHeading",
    components: {

    },
    props: {
        subheading: String
    }
}
</script>

<style scped lang="scss">
h2 {
    font-family: OriginTech;
    font-size: 60px !important;
}

section.insight {
    h2 {
        color: rgb(0, 0, 0);
    }
}


@media(min-width:320px) and (max-width:575px) {
    h2 {
        font-size: 28px !important;
    }
}

@media(min-width:576px) and (max-width:767px) {
    h2 {
        font-size: 36px !important;
    }
}
@media(min-width:768px) and (max-width:991px) {
    h2 {
        font-size: 42px !important;
    }
}
@media(min-width:992px) and (max-width:1199px) {
    h2 {
        font-size: 46px !important;
    }
}
</style>