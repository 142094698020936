<template>
    <div class="main-div meta_slider home_new_slider">
        <swiper :slidesPerView="1" :spaceBetween="0" :loop="true" navigation :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }" :breakpoints="{
            '575': {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            '640': {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            '768': {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            '992': {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            '1024': {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            '1400': {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        }" :modules="modules" :pagination="{ clickable: true }" class="mySwiper" :pauseOnHover="true"
            @mouseenter="stopSwiperAutoplay" @mouseleave="startSwiperAutoplay">
            <swiper-slide class=".swiper-container" v-for="(home_data, index) in home_data" :key="index">
                <div class="swipe-data text-center">
                    <!-- <img :src="require(`@/assets/img/${home_data.img}.svg`)" alt="" class="img-fluid" /> -->
                    <img :src="require(`@/assets/img/${home_data.img}.svg`)" alt="" class="img-fluid" />
                    <h4 class="mt-3">{{ home_data.head }}</h4>
                    <p class="para">{{ home_data.para }}</p>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
// import Swiper core and required modules
import { Pagination, Autoplay, A11y } from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// Import Swiper styles
export default {
    components: {
        Swiper,
        SwiperSlide,
    },

    mounted: function () {



    },
    data() {
        return {
            home_data: [
                {
                    img: "aa",
                    head: "POX DApps",
                    para: "POX boasts the fastest-growing DApp ecosystem and overtook EOS to be the second largest public chain platform for DApps. POX DApps were listed on the frontpage of SAMSUNG Galaxy Store."
                },
                // {
                //     img: "bb",
                //     head: "JustStable",
                //     para: "POX's first decentralized stablecoin lending platform that provides the best gateway to access DeFi."
                // },
                {
                    img: "cc",
                    head: "Polink",
                    para: "WinkLink is an official oracle project running on the POX network that safely feeds on-chain smart contracts with real-world data."
                },
                {
                    img: "dd",
                    head: "POX Wallet",
                    para: "It is created by outstanding community developers of POX and has established in-depth cooperation with a number of world-class wallets."
                },
                {
                    img: "ee",
                    head: "POX SR",
                    para: "POX Super Representatives are elected through decentralized voting and are responsible for the on-chain governance of POX community. Through voting for SRs, voters can earn rewards for participating in building the POX ecosystem."
                },
                // {
                //     img: "ff",
                //     head: "SUN",
                //     para: "SUN token is a social experiment dedicated to the development of POX's DeFi ecosystem. Designed as the quintessential Bitcoin equivalent on POX, SUN features zero VC investments, zero PE investments, no pre-mining or reserve for the team, and is fully operated by the community through its open-source smart contracts."
                // },
                {
                    img: "gg",
                    head: "POXSCAN",
                    para: "The first-ever POX-based blockchain explorer that provides comprehensive on-chain data for queries and supports token creation."
                },
                {
                    img: "hh",
                    head: "PRC20-USDX",
                    para: "A PRC-20 based stablecoin minted against reserve of pollux coin with fastest additional issuance and extremely low handling fee, which enjoys enormous popularity among users since its first day if issuance"
                },
                {
                    img: "ii",
                    head: "POX Network",
                    para: "The scaling solution for POX MainNet that provides unlimited scalability with low resource consumption, high security and customizability."
                },
                {
                    img: "jj",
                    head: "Application Scenario",
                    para: "POX is widely used in various scenarios including payment, purchases and voting both within and outside the POX ecosystem. For instance, TRX is supported by credit card Spend and POX ATM machine for POX payment and online transfer respectively."
                },
                // {
                //     img: "kk",
                //     head: "JustLend DAO",
                //     para: "JustLend DAO is POX's first official lending platform. The interest rates of its fund pools are determined by an algorithm based on the supply and demand of POX assets. JustLend DAO aims to build an ideal decentralized lending protocol and become POX's first official algorithm-powered decentralized bank."
                // },
                // {
                //     img: "ll",
                //     head: "POX Crypto ETFs",
                //     para: "POX Research's report discusses POX ETN listing, global settlement vision, stablecoin comparison, supply mechanisms, Web 3.0 ecosystems, inclusivity in blockchain, stablecoin dominance, next-gen payment systems, smart contracts, DeFi, and projects like BTFS, BTTC, and APENET aiming to revitalize ecosystems and connect artists with token economies."
                // },

            ],
        };
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Pagination, Autoplay, A11y],
        };
    },
};
</script>

<style scoped>
.swiper-slide {
    border-radius: 8px;
    height: 100%;
}

/* .swipe-data img {
    width: 50px;
  } */
.swipe-data {
    border-radius: 12px;
    border: 1px solid #373737;
    background: #151515;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
    padding: 20px;
    height: 100%;
    min-height: 403px;
}

.swipe-data h4 {
    font-family: OriginTech;
    font-size: 28px;
}

.swipe-data h5 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.swipe-data p {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

@media (min-width: 320px) and (max-width: 991px) {
    .swipe-data {
        padding: 15px;
    }

    .swipe-data h5 {
        font-size: 16px;
    }
}

@media (min-width: 576px) and (max-width: 991px) {
    .swipe-data p {
        font-size: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1299px) {
    .swipe-data p {
        font-size: 15px;
    }
}

@media (min-width: 320px) and (max-width: 1299px) {
    .swipe-data {
        padding: 15px;
    }

    .swipe-data h5 {
        font-size: 16px;
    }
}
</style>